
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import logo from "./logo.jpg";

function Images(){

return ( <div className=" w-25 p-3 ">
    <img src={logo} className="img-fluid" alt="logo" />
    </div>);
}


function Contents() {

   
    const year = '2024-2025';
    const message = 'Please select which part of the Course you are registering for, and click the appropriate pay button as below.';


 return(
 <div className="default-margin"> 
        
     <Images/>

    <div>
    <h1> Welcome to the payment portal</h1>

    <section id="pricing" className="pricing-content section-padding">
        <div className="container">
            <div className="section-title text-center">
                <h3>Courses and fees for {year}</h3>
                <p>{message}</p>
            </div>
            <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <h2>Paying in full? </h2>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse  collapse show" data-bs-parent="#accordionExample">
      <div className="accordion-body">
            <div className="row text-center">
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0"  >
                    <div className="pricing_design">
                        <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology</h2>
                                <span>Part A</span>
                                
                                <h1>£2,730.00</h1>
                                
                                
                               
                            </div>

                            <div className="pricing-price">

                            </div>

                          
                            <a href="https://buy.stripe.com/00g5n7cdHh1d82A5kW" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" >
                    <div className="pricing_design">
                    <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology </h2>
                                <span>Part B</span>
                                <h1>£2,730.00</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            <a href="https://buy.stripe.com/bIY5n76Tn5iv0A814H" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0" >
                <div className="pricing_design">
                    <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology</h2>
                                <span>Part C</span>
                                <h1>£1,855.00</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            
                            <a href="https://buy.stripe.com/00g6rb5Pj7qDfv2aFq" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      
    </div>
  </div>
  <div className="accordion-item">
    <h1 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      <h2>
     Paying in instalments?</h2>
      </button>
    </h1>
    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <div className="row text-center">
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0"  >
                    <div className="pricing_design">
                        <div className="single-pricing">
                            <div className="price-head">
                                <h2> MSc Oncology Part A</h2>
                                <span>1st instalment</span>
                                <h1>£1,377.50</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>


                            
                            <a href="https://buy.stripe.com/14kbLv0uZbGTgz6aFk" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" >
                    <div className="pricing_design">
                    <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology Part A </h2>
                                <span>2nd instalment</span>
                                <h1>£1,377.50</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            <a href="https://buy.stripe.com/4gw4j34Lf26jdmU28P" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
            
               
            </div>

<br/>

<div className="row text-center">
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0"  >
                    <div className="pricing_design">
                        <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology Part B</h2>
                                <span>1st instalment</span>
                                <h1>£1,377.50</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            <a href="https://buy.stripe.com/3cs3eZfpTdP16YwaFm" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" >
                    <div className="pricing_design">
                    <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology Part B</h2>
                                <span>2nd instalment</span>
                                <h1>£1,377.50</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            <a href="https://buy.stripe.com/cN24j33Hb7qDciQ5l3" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                
            </div>


            <br/>

<div className="row text-center">
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0"  >
                    <div className="pricing_design">
                        <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology Part C</h2>
                                <span>1st instalment</span>
                                <h1>£940.00</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            <a href="https://buy.stripe.com/bIY5n7fpTbGTeqY5l4" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" >
                    <div className="pricing_design">
                    <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology Part C</h2>
                                <span>2nd instalment</span>
                               
                                <h1>£940.00</h1>
                            
                            </div>

                            <div className="pricing-price">

                            </div>
                           
                            
                            <a href="https://buy.stripe.com/8wMeXHelP3an82AaFp" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                
            </div>








      </div>
    </div>
  </div>




  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      <h2>
     Are you an ICR ACF student?</h2>
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <div className="row text-center">
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0"  >
                    <div className="pricing_design">
                        <div className="single-pricing">
                            <div className="price-head">
                                <h2>MSc Oncology ICR ACF</h2>
                                <span>Part A</span>
                                <h1>£1,365.00</h1>
                              
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                          

                            <a href="https://buy.stripe.com/fZeeXH6TndP11Ec9Be" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" >
                    <div className="pricing_design">
                    <div className="single-pricing">
                            <div className="price-head">
                            <h2>MSc Oncology ICR ACF</h2>
                                <span>Part B</span>
                                <h1>£1,820.00</h1>
                                
                            </div>

                            <div className="pricing-price">

                            </div>
                            <a href="https://buy.stripe.com/9AQ9Dn1z3fX982A9Bf" className="price_btn" target="_blank">Pay</a>
                        </div>
                    </div>
                </div>
                
            </div>





















      </div>
    </div>
  </div>
</div>
            <br />
            
        </div>
        
    </section>
    </div>


</div>
);
 


}

export default Contents;