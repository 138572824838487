import React from 'react';

import './App.css';
import Contents from './Contents'


function App() {
  return (
    <div className="App border-icr">
      <header className="App-header">
     

<div>
<Contents/>
</div>


      </header>

    
    </div>
  );
}



export default App;
